import { useTranslation } from '@/utils/useTranslation';
import Tooltip from '@/components/Tooltip';
import {
  Container,
  InfoIcon,
  PartnerLogo,
  PartnerLogoContainer,
  SectionTitle,
  TitleContainer,
} from './styledComponents';
import type { Partner } from '@/common/types';

type PartnerProps = {
  type: 'iagAccounts' | 'loyaltyPartners';
  partners: Partner[];
  className?: string;
};

const Partners = (props: PartnerProps) => {
  const { partners, type, className } = props;

  const { t } = useTranslation('common');

  if (partners.length === 0) return null;

  return (
    <Container className={className}>
      <TitleContainer>
        <SectionTitle>{t(`${type}.title`)}</SectionTitle>
        <InfoIcon
          iconName="info"
          data-tooltip-content={t(`${type}.tooltip.description`)}
          data-tooltip-title={t(`${type}.tooltip.title`)}
          data-tooltip-id={`${type}`}
        />
        <Tooltip id={`${type}`} position={'top'} />
      </TitleContainer>
      <PartnerLogoContainer>
        {partners.map(partner => (
          <div key={`partner-logo-${partner.code}`}>
            <PartnerLogo
              src={`/assets/logos/logo_${partner.code}.png`}
              alt={`${partner.name} ${t('logo')}`}
              data-testid={partner.code}
              data-tooltip-content={partner.name}
              data-tooltip-id={partner.code}
            />
            <Tooltip id={partner.code} position={'bottom'} />
          </div>
        ))}
      </PartnerLogoContainer>
    </Container>
  );
};

export default Partners;
