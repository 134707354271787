import { Button as StyledButton } from './styledComponents';

type Props = {
  label: string;
  disabled?: boolean;
  onClick?: () => void;
};

const Button = ({ label, disabled, ...props }: Props) => {
  return (
    <StyledButton {...props} disabled={disabled}>
      {label}
    </StyledButton>
  );
};

export default Button;
