import { useGlobalContext } from '../GlobalProvider';
import { Link, LoginButton as StyledButton } from './styledComponents';
import { useTranslation } from '@/utils/useTranslation';

interface Props {
  className?: string;
}

const LoginButton = (props: Props) => {
  const { programmeId = 'BAEC' } = useGlobalContext();
  const { t } = useTranslation(`${programmeId}/login`);

  return (
    <Link href="/api/auth/login" {...props}>
      <StyledButton label={t('loginButton')} />
    </Link>
  );
};

export default LoginButton;
