import { media } from '@/utils/viewports';
import styled from 'styled-components';
import Icon from '@/components/Icon';

export const TopToasterContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const TopToasterMessage = styled.p`
  color: inherit;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.5rem;
  padding: 1.2rem 1.2rem 1.2rem 0rem;
`;

export const ToasterContainer = styled.div`
  .Toastify__toast {
    border-radius: 4px;
    margin: 1rem;
    padding: 0;
  }

  .Toastify__toast-body {
    margin: 0;
    padding: 0;
  }

  .Toastify__close-button {
    color: white;
    padding: 1rem 1rem 0 0;
  }

  .Toastify__toast-container--top-center {
    max-width: 126rem;
    box-sizing: border-box;
    width: 100%;
    --toastify-toast-min-height: 4.8rem;
    padding: 0;
    margin: 13.2rem auto 0;
  }

  ${media.m} {
    .Toastify__toast-container--top-center {
      margin-top: 11rem;
    }
  }

  ${media.l} {
    .Toastify__toast-container--bottom-left {
      max-width: 570px;
      width: 100%;
      padding: 0px;
    }
  }

  ${media.m} {
    .Toastify__toast-container--bottom-left {
      max-width: 288px;
      width: 100%;
      margin-left: 1.6rem;
    }
  }
`;

export const TopStyledIcon = styled(Icon).withConfig({
  shouldForwardProp: prop => prop === 'iconName',
})`
  width: 4rem;
  margin: 1.2rem 1rem 0 1.2rem;
`;

export const BottomToasterContentContainer = styled.div`
  ${media.m} {
    display: block;
    width: 25rem;
  }
`;

export const BottomToasterTitle = styled.span`
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1rem;
`;

export const BottomToasterMessage = styled.span`
  color: #021b41;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.5rem;
`;
