import styled from 'styled-components';
import { media } from '@/utils/viewports';

export const Button = styled.button`
  align-self: center;
  background: ${props => props.theme.button.background};
  border-radius: ${props => props.theme.button.borderRadius};
  border: 1px solid ${props => props.theme.button.borderColor};
  box-shadow: ${props => props.theme.button.boxShadow};
  color: ${props => props.theme.button.color};
  cursor: pointer;
  font-family: ${props => props.theme.button.fontFamily};
  font-size: 1.4rem;
  letter-spacing: 0.02rem;
  line-height: 2.2rem;
  margin: 0 1.6rem;
  max-width: 30rem;
  padding: 0.45rem 1.6rem 0.55rem;
  width: 100%;
  text-transform: ${props => props.theme.button.textTransform};

  ${media.xxl} {
    max-width: 29rem;
  }

  ${media.xl} {
    max-width: 21.5rem;
  }

  ${media.l} {
    max-width: 26.4rem;
  }

  ${media.m} {
    max-width: unset;
  }

  &:hover,
  :active {
    border: 1px solid ${props => props.theme.button.highlight};
    background: ${props => props.theme.button.highlight};
  }

  &:disabled {
    border: 1px solid ${props => props.theme.button.disabled};
    background: ${props => props.theme.button.disabled};
  }
`;
