import {
  SpinnerWrapper,
  SpinnerElement,
  SpinnerLabel,
} from './styledComponent';

type SpinnerProps = React.HTMLAttributes<HTMLDivElement> & {
  text?: string;
};

const Spinner = (props: SpinnerProps) => {
  const { text, ...otherProps } = props;

  return (
    <SpinnerWrapper {...otherProps} data-testid="spinner-wrapper">
      <SpinnerElement />
      {text && <SpinnerLabel data-testid="spinner-label">{text}</SpinnerLabel>}
    </SpinnerWrapper>
  );
};

export default Spinner;
