import styled, { css } from 'styled-components';
import { media } from '@/utils/viewports';
import Partners from './components/Partners';

export const Wrapper = styled.div`
  display: flex;

  ${media.m} {
    flex-direction: column;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.login.containerBackground};
  border-radius: 8px;
  margin: ${props => props.theme.login.margin};
  padding: 8rem;
  width: 100%;
  position: relative;
  width: 91.8rem;
  box-sizing: border-box;

  ${media.l} {
    margin: 6.4rem 0;
    padding: 6rem;
  }

  ${media.m} {
    margin: 0;
    padding: 4rem 4rem 5rem;
    width: 100vw;
  }
`;

export const Row = styled.div<{ $isAtrp: boolean }>`
  display: flex;
  gap: ${props => props.theme.login.row.gap};

  ${media.l} {
    margin-right: ${props => props.theme.login.row.marginRight};
    gap: ${props => props.theme.login.row.tabletGap};
  }

  ${({ $isAtrp }) =>
    !$isAtrp &&
    css`
      ${media.m} {
        margin-right: auto;
        margin-left: auto;
        width: 32rem;
      }
    `}
`;

export const InnerContainer = styled.div`
  flex-basis: 55%;

  ${media.m} {
    flex-basis: unset;
  }
`;

export const PartnersContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${media.l} {
    flex-direction: row;
  }

  @media (max-width: 749px) {
    flex-direction: column;
  }

  ${media.m} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Title = styled.h1<{ $isAtrp: boolean }>`
  font-family: ${props => props.theme.login.title.fontFamily};
  font-weight: ${props => props.theme.login.title.fontWeight};
  font-size: ${props => props.theme.login.title.fontSize};
  color: ${props => props.theme.login.title.color};
  margin-bottom: 1.4rem;
  max-width: 41.8rem;
  line-height: ${props => props.theme.login.title.lineHeight};
  text-transform: ${props => props.theme.login.title.textTransform};

  ${media.xl} {
    font-size: 3.8rem;
  }

  ${media.l} {
    font-size: 3rem;
  }

  ${media.m} {
    text-align: center;
  }

  ${({ $isAtrp }) =>
    !$isAtrp &&
    css`
      ${media.m} {
        display: none;
      }
    `}
`;

export const SubTitle = styled.h2`
  font-family: ${props => props.theme.login.subtitle.fontFamily};
  font-weight: ${props => props.theme.login.subtitle.fontWeight};
  font-size: ${props => props.theme.login.subtitle.fontSize};
  color: ${props => props.theme.login.subtitle.color};
  margin-bottom: 2.8rem;
  max-width: 41.8rem;
  line-height: ${props => props.theme.login.subtitle.lineHeight};
  text-transform: ${props => props.theme.login.title.textTransform};

  ${media.l} {
    font-size: 1.6rem;
    margin-bottom: 2.6rem;
  }

  ${media.m} {
    display: none;
  }
`;

export const Description = styled.p`
  font-family: ${props => props.theme.login.description.fontFamily};
  font-size: ${props => props.theme.login.description.fontSize};
  color: ${props => props.theme.login.description.color};
  margin-bottom: 2.8rem;
  max-width: 41.8rem;
  line-height: ${props => props.theme.login.description.lineHeight};
  font-weight: ${props => props.theme.login.description.fontWeight};

  ${media.l} {
    font-size: 1.2rem;
    margin-bottom: 5rem;
  }

  ${media.m} {
    margin-bottom: 3rem;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  flex-basis: 45%;
  top: -2.5rem;
  right: 4.5rem;

  @media (max-width: 1200px) {
    right: -9.7rem;
  }

  ${media.m} {
    display: none;
  }
`;

export const SmallImage = styled.img`
  display: none;
  width: 100%;
  margin-bottom: -16rem;
  margin-top: 3rem;
  width: 36rem;
  height: 55rem;

  ${media.s} {
    width: auto;
    display: unset;
  }

  ${media.m} {
    width: 32rem;
    margin-left: auto;
    margin-right: auto;
    display: unset;
  }
`;

export const WindowImage = styled.img`
  height: 49.8rem;
  width: 32rem;
`;

export const VuelingCoupleImageSm = styled.img`
  display: none;

  ${media.m} {
    display: flex;
    align-self: center;
    max-width: 30rem;
    margin-bottom: 3.2rem;
  }
`;

export const VuelingCoupleImageXl = styled.img`
  display: block;
  width: 32rem;
  height: 50rem;

  ${media.xl} {
    width: 27.2rem;
    height: 42.5rem;
  }

  ${media.m} {
    display: none;
  }
`;

export const StyledPartners = styled(Partners)`
  margin: 0 1.9rem 7rem 0;

  ${({ theme }) =>
    theme.login.partners?.mobileMargin &&
    css`
      ${media.m} {
        margin: ${theme.login.partners?.mobileMargin};
      }
    `}
`;
