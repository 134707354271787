import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

export const SpinnerElement = styled.div`
  width: 5rem;
  height: 5rem;
  border: 0.5rem solid ${props => props.theme.spinner.borderColor};
  border-top: 0.5rem solid ${props => props.theme.spinner.borderTopColor};
  border-radius: 50%;
  animation: ${rotate} 1.2s linear infinite;
`;

export const SpinnerLabel = styled.div`
  margin: 2rem 0;
  color: ${props => props.theme.spinner.labelColor};
  font-size: 2rem;
  font-weight: bold;
`;
