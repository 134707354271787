import styled from 'styled-components';
import StyledButton from '@/components/Button';
import { media } from '@/utils/viewports';

export const Link = styled.a`
  text-align: ${props => props.theme.login.button.alignment};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  ${media.m} {
    display: flex;
    justify-content: center;
  }
`;

export const LoginButton = styled(StyledButton)`
  font-size: 1.6rem;
  line-height: 2.4rem;
  padding: 1.5rem 2rem;
  white-space: nowrap;
  font-weight: ${props => props.theme.login.button.fontWeight};
  margin: 0;

  ${media.m} {
    max-width: 30rem;
    width: 100%;
  }
`;
