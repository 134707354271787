import {
  type Id,
  ToastContainer,
  type ToastContent,
  type ToastOptions,
  toast,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { type ToasterProps } from './type';
import {
  ToasterContainer,
  TopToasterMessage,
  TopToasterContentContainer,
  TopStyledIcon,
  BottomToasterTitle,
  BottomToasterMessage,
  BottomToasterContentContainer,
} from './styledComponents';
import { getToasterPosition } from '@/utils/toasterPosition';

const Toaster = () => {
  const position = getToasterPosition();

  return (
    <ToasterContainer>
      <ToastContainer
        position={position}
        autoClose={5000}
        limit={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme="light"
        icon={false}
      />
    </ToasterContainer>
  );
};

export default Toaster;

const ToasterContent = (
  props: ToasterProps,
  toastMethod: <TData = unknown>(
    content: ToastContent<TData>,
    options?: ToastOptions<Record<string, never>> | undefined,
  ) => Id,
  style: { background: string; borderLeft: string; color: string },
) => {
  const { title, message, position } = props;
  const isTopCenterToaster = position === 'top-center';

  const toastOptions: ToastOptions = {
    style,
    toastId: title + message,
    position: position,
  };

  toastMethod(
    <>
      {isTopCenterToaster ? (
        <TopToasterContentContainer>
          <TopStyledIcon iconName="alert" />
          <TopToasterMessage>
            <strong>{title}</strong>
            {message}
          </TopToasterMessage>
        </TopToasterContentContainer>
      ) : (
        <BottomToasterContentContainer>
          <BottomToasterTitle>{title}</BottomToasterTitle>
          <br />
          <BottomToasterMessage>{message}</BottomToasterMessage>
        </BottomToasterContentContainer>
      )}
    </>,
    toastOptions,
  );
};

export const ToasterError = (props: ToasterProps) => {
  const errorStyle = {
    background: props.theme.error.background,
    borderLeft: props.theme.error.borderLeft,
    color: props.theme.error.color,
  };
  ToasterContent(props, toast.error, errorStyle);
};

export const ToasterSuccess = (props: ToasterProps) => {
  const successStyle = {
    background: props.theme.success.background,
    borderLeft: props.theme.success.borderLeft,
    color: props.theme.success.color,
  };
  ToasterContent(props, toast.success, successStyle);
};

export const ToasterPending = (props: ToasterProps) => {
  const pendingStyle = {
    background: props.theme.pending.background,
    borderLeft: props.theme.pending.borderLeft,
    color: props.theme.pending.color,
  };
  ToasterContent(props, toast.info, pendingStyle);
};
