import { Trans } from 'react-i18next';
import { useTranslation } from '@/utils/useTranslation';
import {
  Wrapper,
  Container,
  Title,
  SubTitle,
  Description,
  Row,
  InnerContainer,
  ImageWrapper,
  SmallImage,
  PartnersContainer,
  WindowImage,
  VuelingCoupleImageXl,
  VuelingCoupleImageSm,
  StyledPartners,
} from './styledComponents';
import { useUser } from '@auth0/nextjs-auth0/client';
import Spinner from '../Spinner';
import { useRouter } from 'next/router';
import { ToasterError } from '../Toaster';
import { useTheme } from 'styled-components';
import type { SortedPartners } from '@/common/types';
import { useGlobalContext } from '../GlobalProvider';
import { setToasterPosition } from '@/utils/toasterPosition';
import LoginButton from '@/components/LoginButton';

export type LoginProps = {
  partners: SortedPartners;
};

const Login = ({ partners }: LoginProps) => {
  const theme = useTheme();
  const router = useRouter();

  const { programmeId = 'BAEC' } = useGlobalContext();

  const { t } = useTranslation(`${programmeId}/login`);

  const { user, error, isLoading } = useUser();
  if (isLoading) return <Spinner />;

  setToasterPosition('bottom-left');
  if (error?.message)
    ToasterError({
      title: '',
      message: error.message,
      theme: theme.toaster,
      position: 'bottom-left',
    });

  if (user) router.push('/wallet');

  return (
    <>
      {!user && (
        <Wrapper>
          {programmeId !== 'ATRP' && (
            <SmallImage
              src="/assets/plain_window_text.png"
              alt="Plane window with the text Welcome to your Avios Wallet"
            />
          )}

          <Container>
            {programmeId === 'ATRP' && (
              <VuelingCoupleImageSm
                src="/assets/vueling/vueling_landing_page_sm.png"
                alt="Happy couple on beach"
              />
            )}
            <Row $isAtrp={programmeId === 'ATRP'}>
              <InnerContainer>
                <Title $isAtrp={programmeId === 'ATRP'}>{t('title')}</Title>
                <SubTitle>{t('subtitle')}</SubTitle>
                <Description>
                  <Trans
                    ns={`${programmeId}/login`}
                    i18nKey={'description'}
                    components={{ br: <br /> }}
                  />
                </Description>
                <PartnersContainer>
                  <StyledPartners
                    type="iagAccounts"
                    partners={partners.iagAccounts}
                  />
                  <StyledPartners
                    type="loyaltyPartners"
                    partners={partners.loyaltyPartners}
                  />
                </PartnersContainer>
              </InnerContainer>
              {programmeId === 'ATRP' ? (
                <VuelingCoupleImageXl
                  src="/assets/vueling/vueling_landing_page_xl.png"
                  alt="Happy couple"
                />
              ) : (
                <ImageWrapper>
                  <WindowImage src="/assets/plain_window.png" alt="" />
                </ImageWrapper>
              )}
            </Row>
            <LoginButton />
          </Container>
        </Wrapper>
      )}
    </>
  );
};

export default Login;
