import styled from 'styled-components';
import { media } from '@/utils/viewports';
import Icon from '@/components/Icon';

export const Container = styled.div`
  margin: 0 1.9rem 7.8rem 0;

  &:not(:only-child):last-child {
    margin-left: 3rem;
  }

  ${media.xl} {
    margin-bottom: 3rem;
    &:not(:only-child):last-child {
      margin-left: 0rem;
    }
  }

  ${media.l} {
    margin-bottom: 3rem;
    &:not(:only-child):last-child {
      margin-left: 0rem;
    }
  }

  ${media.m} {
    &:not(:only-child):last-child {
      margin-left: 0rem;
    }
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SectionTitle = styled.h3`
  font-family: ${props => props.theme.loginPartners.title.fontFamily};
  font-size: ${props => props.theme.loginPartners.title.fontSize};
  font-weight: ${props => props.theme.loginPartners.title.fontWeight};
  color: ${props => props.theme.loginPartners.title.color};
  line-height: ${props => props.theme.loginPartners.title.lineHeight};

  ${media.m} {
    font-size: 1rem;
  }
`;

export const InfoIcon = styled(Icon).withConfig({
  shouldForwardProp: prop =>
    prop === 'iconName' || prop.includes('data-tooltip'),
})`
  margin: 0rem 0rem 0rem 0.6rem;
  &:hover {
    & path {
      fill: #808080;
    }
  }
  & path {
    fill: #b3b3b3;
  }
`;

export const PartnerLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PartnerLogo = styled.img`
  width: 4rem;
  height: 4rem;
  margin: 1rem 0.5rem 0 0;

  ${media.xl} {
    width: 3.2rem;
    height: 3.2rem;
  }

  ${media.m} {
    width: 2.8rem;
    height: 2.8rem;
  }

  ${media.s} {
    width: 2.8rem;
  }
`;
