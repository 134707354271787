import { Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components';
import { media } from '@/utils/viewports';

export const StyledTooltip = styled(ReactTooltip)`
  &.custom-tooltip {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
    color: ${props => props.theme.tooltip.color};
    font-family: ${props => props.theme.tooltip.fontFamily};
    font-size: ${props => props.theme.tooltip.fontSize};
    font-weight: 400;
    line-height: 150%;
    max-width: 32.6rem;
    opacity: 1;
    padding: 0.8rem 1.2rem;
    z-index: 1;

    ${media.s} {
      max-width: 26rem;
    }

    .tooltip-title {
      color: ${props => props.theme.tooltip.title.color};
      font-family: ${props => props.theme.tooltip.title.fontFamily};
      font-size: ${props => props.theme.tooltip.title.fontSize};
      font-weight: ${props => props.theme.tooltip.title.fontWeight};
      letter-spacing: ${props => props.theme.tooltip.title.letterSpacing};
      line-height: 150%;
      text-transform: ${props => props.theme.tooltip.title.textTransform};
    }
  }
`;
