import type { PlacesType } from 'react-tooltip';
import { StyledTooltip } from './styledComponent';

type TooltipProps = {
  id: string;
  position?: PlacesType;
};

const Tooltip = ({ id, position = 'top' }: TooltipProps) => {
  return (
    <StyledTooltip
      id={id}
      place={position}
      className="custom-tooltip"
      render={({ content, activeAnchor }) => {
        const title = activeAnchor?.getAttribute('data-tooltip-title');
        return (
          <div>
            {title && (
              <>
                <span className="tooltip-title">{title}</span>
                <br />
              </>
            )}
            <span>{content}</span>
          </div>
        );
      }}
    />
  );
};

export default Tooltip;
